import React, { Component,fragment } from 'react';
import RestClient from '../../RestApi/RestClient';
import AppUrl from '../../RestApi/AppUrl';

class BidderData extends Component {

    constructor() {
        super();
        this.state={
            data:[],
            number:'',
            email:'',
            password:'',
        }
    }

    componentDidMount() {
        if(this.props.user) {
            RestClient.GetRequest(AppUrl.BidderData + '/' + this.props.user).then(result => {
                this.setState({data: result});
            }).catch(error => {
                this.setState({data: []});
            });
        }
    }

    reRender = () => {
        RestClient.GetRequest(AppUrl.BidderData + '/' + this.props.user).then(result => {
            this.setState({data: result});
        }).catch(error => {
            this.setState({data: []});
        });
    };

    // Login Form Submit
    formSubmit = (e) =>{
        e.preventDefault();
        const BidderCopartData={
            user_id:this.props.user,
            name:this.state.number,
            email:this.state.email,
            password:this.state.password,
        }

        RestClient.PostRequest(AppUrl.BidderInsert, BidderCopartData).then(result=>{
            this.reRender();
        }).catch(error=>{
            this.setState({message:error.response.data.message})
        });

    }


    render() {

        let bidderTable="";
        let bidderItem=this.state.data;
        if(bidderItem){
            bidderTable=(
                <table className="table table-striped">
                    <thead className="thead-dark">
                    <tr>
                        <th scope="col">Member ID</th>
                        <th scope="col">Member Email Address</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bidderItem.map((one, index) => {
                        return (
                            <tr>
                                <td>{one.name}</td>
                                <td>{one.email}</td>
                                <td>
                                    <a href="#" className="btn btn-primary mr-2">Delete</a>
                                    <a href="#" className="btn btn-primary">Edit</a>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

            )
        }

    return(
        <fragment>
            <h3 className="text-center">Member Account</h3>
            {bidderTable}
            <form className="needs-validation mt-5 border-top border-dark" onSubmit={this.formSubmit}>
                <div className="form-row">
                    <div className="col-md-3 mb-3">
                        <label htmlFor="validationTooltip01">Member Number/ID</label>
                        <input type="text" className="form-control" placeholder="Member Number/ID" required onChange={(e)=>{this.setState({number:e.target.value})}}/>
                        <div className="valid-tooltip">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <label htmlFor="validationTooltip02">Member Email Address</label>
                        <input type="email" className="form-control" placeholder="Member Email Address" required onChange={(e)=>{this.setState({email:e.target.value})}}/>
                        <div className="valid-tooltip">
                            Looks good!
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <label htmlFor="inputPassword1">Member Password</label>
                        <input type="password" className="form-control" required onChange={(e)=>{this.setState({password:e.target.value})}}/>
                        <div className="valid-tooltip">
                            Looks good!
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary" type="submit">Add Member</button>
            </form>
        </fragment>

    );
  }
}
export default BidderData